import React from 'react'
import Header from '../../component/Header'
import CertificateinMobileAppDevelopment from '../CertificateinMobileAppDevelopment'
import Footer from '../../component/Footer'

export default function LayoutCertificateinMobileAppDevelopment() {
    return (
        <div>
            <Header />
            <CertificateinMobileAppDevelopment />
            <Footer />
        </div>
    )
}
