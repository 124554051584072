import React from 'react'
import { Link } from 'react-router-dom'

export default function Career() {
  return (
    <>
    {/* <!-- breadcrumb-area --> */}
    <div class="breadcrumb__area breadcrumb__bg breadcrumb__bg-two breadcrumbcustom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb__content">
                        <h4>Career</h4>
                        <nav class="breadcrumb">
                            <span property="itemListElement" typeof="ListItem">
                                <Link to='/'>Home</Link>
                            </span>
                            <span class="breadcrumb-separator"><i class="fas fa-angle-right"></i></span>
                            <span property="itemListElement" typeof="ListItem">
                                <Link to="/career">Career</Link>
                            </span>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="breadcrumb__shape-wrap">
            <img src="assets/img/others/breadcrumb_shape01.svg" alt="img" class="alltuchtopdown" />
            <img src="assets/img/others/breadcrumb_shape02.svg" alt="img" data-aos="fade-right" data-aos-delay="300" />
            <img src="assets/img/others/breadcrumb_shape03.svg" alt="img" data-aos="fade-up" data-aos-delay="400" />
            <img src="assets/img/others/breadcrumb_shape04.svg" alt="img" data-aos="fade-down-left" data-aos-delay="400" />
            <img src="assets/img/others/breadcrumb_shape05.svg" alt="img" data-aos="fade-left" data-aos-delay="400" />
        </div>
    </div>
    {/* <!-- breadcrumb-area-end --> */}
    <h2>Updated Soon...</h2>
</>
  )
}
