import React from 'react'
import Header from '../../component/Header'
import DigitalMarketing from '../DigitalMarketing'
import Footer from '../../component/Footer'

export default function LayoutDigitalMarketing() {
    return (
        <div>
            <Header />
            <DigitalMarketing />
            <Footer />
        </div>
    )
}
