import React from 'react'
import Header from '../../component/Header'
import Courses from '../Courses'
import Footer from '../../component/Footer'

export default function LayoutCourses() {
  return (
    <div>
      <Header/>
      <Courses/>
      <Footer/>
    </div>
  )
}
