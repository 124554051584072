import React from 'react'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import Dac from '../Dac'

export default function LayoutDac() {
    return (
        <div>
            <Header />
            <Dac />
            <Footer />
        </div>
    )
}
