import React from 'react'
import Header from '../../component/Header'
import PersonalityDevelopment from '../PersonalityDevelopment'
import Footer from '../../component/Footer'

export default function LayoutPersonalityDevelopment() {
    return (
        <div>
            <Header />
            <PersonalityDevelopment />
            <Footer />
        </div>
    )
}
