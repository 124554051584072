import React from 'react'
import Header from '../../component/Header'
import CertificateinAIML from '../CertificateinAIML'
import Footer from '../../component/Footer'

export default function LayoutCertificateinAIML() {
  return (
    <div>
      <Header/>
      <CertificateinAIML/>
      <Footer/>
    </div>
  )
}
