import React from 'react'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import Home from '../Home'

export default function LayoutIndex() {
  return (
    <>
      <Header />
      <Home />
      <Footer />
    </>
  )
}
