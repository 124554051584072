import React from 'react'
import Header from '../../component/Header'
import BusinessPartnerShip from '../BusinessPartnerShip'
import Footer from '../../component/Footer'

export default function LayoutBusinessPartnerShip() {
    return (
        <div>
            <Header />
            <BusinessPartnerShip />
            <Footer />
        </div>
    )
}
