import React from 'react'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import UIUXGraphicsDesigning from '../UIUXGraphicsDesigning'

export default function LayoutUIUXGraphicsDesigning() {
    return (
        <div>
            <Header />
            <UIUXGraphicsDesigning />
            <Footer />
        </div>
    )
}
