import React from 'react'
import Header from '../../component/Header'
import CertificateInDA from '../CertificateInDA'
import Footer from '../../component/Footer'

export default function LayoutCertificateInDA() {
    return (
        <div>
            <Header />
            <CertificateInDA />
            <Footer />
        </div>
    )
}
