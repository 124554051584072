import React from 'react'
import Header from '../../component/Header'
import FullStackDevelopment from '../FullStackDevelopment'
import Footer from '../../component/Footer'

export default function LayoutFullStackDevelopment() {
    return (
        <div>
            <Header />
            <FullStackDevelopment />
            <Footer />
        </div>
    )
}
